.container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 4px solid #fff;
    border-radius: 22px;
}

[class*="red-snippet_RedSnippet__container"] .container {
    border-radius: 6px;
}
