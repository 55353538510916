.loader {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: var(--snow-margin-m);
    padding-top: var(--snow-margin-xl);
    width: 100%;
}

.loadMore,
.loadAgain {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.loadMoreButton,
.loadAgainButton {
    width: 100%;
}

.loadMore {
    padding-bottom: var(--snow-margin-m);
    padding-top: var(--snow-margin-xl);
}

.loadAgain {
    padding-top: var(--snow-margin-s);
}

.root {
    max-width: 100%;
}

@media (max-width: 768px) {
    .root.isStretched {
        max-width: none;
        transform: translateX(var(--snow-margin-s));
        margin-left: calc(var(--snow-margin-s) * -1);
    }
    .isStretched > div > div {
        padding-right: var(--snow-margin-s);
    }
}
