.loader div {
    box-sizing: border-box;
}

.loader {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin: -4px 0;
}

.loader::before {
    content: "";
    border: 4px solid #ccd0d3;
    border-radius: 1000px;
}

.r1,
.r2,
.p1,
.p2,
.p3,
.p4 {
    display: block;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.r1,
.r2,
.p1,
.p2,
.p3,
.p4,
.loader::before {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.p1,
.p2,
.p3,
.p4 {
    border: 4px solid #444546;
    border-radius: 1000px;
    border-color: #444546 transparent transparent transparent;
}

.p2,
.r1 {
    animation-delay: -0.45s;
}

.p3 {
    animation-delay: -0.3s;
}

.p4 {
    animation-delay: -0.15s;
}

.r1 .dot,
.r2 .dot {
    position: absolute;
    width: 4px;
    height: 100%;
    left: 50%;
}

.r1 .dot {
    transform: translateX(-50%) rotate(45deg);
}

.r2 .dot {
    transform: translateX(-50%) rotate(-45deg);
}

.r1 .dot::before,
.r2 .dot::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
    background-color: #444546;
    border-radius: 100px;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
