.container {
    position: absolute;
    top: 4px;
    right: 0;
}

.containerOnShadow {
    z-index: 1003;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 6px;
    outline: none;
    user-select: none;
    pointer-events: auto;
    border: none;
    cursor: pointer;
    color: #a0a0ab;
    background-color: transparent;

    /*для мобилок дает возможность использовать preventDefault*/
    touch-action: manipulation;
}

.button:hover {
    color: #6c727e;
}

.button svg {
    min-width: 15px;
    min-height: 4px;
    width: auto;
    height: auto;
}

.buttonOnShadow {
    color: #6c727e;
    background-color: #fff;
}
