.wrapper {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

/*делает видимыми банер топ товара*/
.activeTooltip [class*="product-snippet_ProductSnippet__rightTags"] {
    z-index: 1003;
}
.activeTooltip [class*="red-snippet_RedSnippet__promoTags"] {
    z-index: 1003;
}

.activeTooltip [class*="gallery_Gallery__picListWrapper"] {
    z-index: 1002;
}

[class*="red-snippet_RedSnippet__gallery"] {
    z-index: auto;
}

/*Делает инверсию цветов кнопки в момент активного тултипа*/
.activeTooltip [class*="NotInterestingButton__button"] {
    color: #a0a0ab;
    background-color: #fff;
}

.arrowBlock {
    position: absolute;
    color: #fff;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    right: 6px;
    bottom: -40px;

    & > svg {
        display: block;
    }
}

.tooltipContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -290px;
    top: 100%;
    transform: translateY(40px);
    background: #fff;
    border-radius: 12px;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.12));
    padding: 14px 51px 14px 16px;
    transition: opacity 0.3s ease-out;
    opacity: 0;
    pointer-events: none;
    width: 324px;
    z-index: -1;
}

.mobileTooltipContent {
    width: 248px;
    right: -212px;
    transform: translateY(38px);
}

.shown {
    pointer-events: auto;
    opacity: 1;
    z-index: 1002;
}

.shownTooltip {
    pointer-events: none;
    opacity: 1;
    z-index: 1003;
}

.withoutShadow {
    z-index: 1;
}

.redTooltipContent {
    padding: 20px;
}

.close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    pointer-events: auto;
    background: #f0f4f7;
    border-radius: 50%;
}

.close:hover {
    background: #f6f7f8;
}

.close svg {
    height: 16px;
    width: 16px;
}

.text {
    margin-top: 4px;
    color: #898993;
}
