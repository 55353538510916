.container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    padding: 16px 12px;
    border-radius: 24px;
    background-color: #ffffff99;
}

.button {
    background-color: #eef2f6;
    border-radius: 6px;
    padding: 12px 0;
    text-align: center;
    outline: none;
    user-select: none;
    pointer-events: auto;
    border: none;
    cursor: pointer;
    color: #18181b;

    /*для мобилок дает возможность использовать preventDefault*/
    touch-action: manipulation;
}

.button:hover {
    background-color: #f3f6f9;
}

.button + .button {
    margin-top: 8px;
}

.container.hidingComplete {
    justify-content: center;
    pointer-events: none;
}

.hidingCompleteOverlay [class*="product-snippet_ProductSnippet__content"],
.hidingCompleteOverlay [class*="red-snippet_RedSnippet__content"] {
    pointer-events: none;
}

.hidingCompleteOverlay [class*="product-snippet_ProductSnippet__rightTags"],
.hidingCompleteOverlay [class*="red-snippet_RedSnippet__promoTags"] {
    display: none;
}

.openOverlay [class*="product-snippet_ProductSnippet__rightTags"],
.openOverlay [class*="red-snippet_RedSnippet__promoTags"] {
    opacity: 0.6;
}

[class*="red-snippet_RedSnippet__container"] .container {
    border-radius: 6px 6px 0;
}

.openOverlay [class*="product-snippet_ProductSnippet__galleryBlock"],
.openOverlay [class*="red-snippet_RedSnippet__gallery"] {
    pointer-events: none;
}

.hidingCompleteOverlay [class*="red-snippet_RedSnippet__content"] {
    pointer-events: none;
}
