.background {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    min-width: 100%;
    min-height: 100%;

    background: rgba(34, 34, 34, 0.68);
    opacity: 0.45;
}
