.title {
    padding: var(--snow-margin-m) 0;
    margin-bottom: 0;
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link,
.rightPart,
.linkLabel {
    white-space: nowrap;
    overflow: hidden;
}

.link .leftPart {
    flex-shrink: 0;
}

.rightPart {
    display: flex;
    flex-shrink: 1;
    margin-left: 4px;
    align-items: center;
    padding: 0 var(--snow-margin-xs);
}

.linkLabel {
    flex-shrink: 1;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-decoration: none;
    letter-spacing: 0em;
    color: #547dff;
    font-smooth: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

@media (max-width: 375px) {
    .linkLabel {
        font-size: 13px;
        line-height: 16px;
    }
}
