.loadAgain {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.loadAgainButton {
    width: 100%;
}

.loadAgain {
    padding-top: var(--snow-margin-s);
}

.loadAgainButton {
    width: 100%;
}
