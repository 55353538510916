.messageWrapper {
    padding: 6px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #f8fafc;
}

.icon {
    color: #a0a0ab;
}

.message {
    color: #18181b;
    white-space: normal;
}

.icon,
.message {
    display: block;
    text-align: center;
}
