.horizontalProductFeed {
    padding: 5px 12px 0;
    background-color: #ffffff;

    li {
        margin-bottom: 12px;
    }
}

.wrapper {
    background-color: #f0f4f7;
    border-radius: 12px;
    overflow: hidden;
}

.redChoiceBanner {
    border-radius: 6px;
}

.redShelf {
    grid-column: 1 / -1;
}

.combo {
    position: relative;
}

.combo div {
    z-index: 1;
}

.combo::before,
.combo::after {
    content: "";
    display: block;
    position: absolute;
    width: 700px;
    height: 700px;
    border-radius: 10000px;
    left: 100%;
    top: 100%;
    transform: translate(-43%, -43%);
}

.combo::before {
    background: repeating-conic-gradient(from 0deg, #f8f9fb 0deg 10deg, #f0effb 10deg 20deg);
}

.combo::after {
    background: radial-gradient(transparent 0%, #f8f9fb 70%);
}

.comboHeader > div:first-child {
    width: initial;
}

.combo .comboHeader {
    font-weight: 525;
}

/* Блок хаков. */
/* TODO: Грохнуть, когда соответствующие правки выкатятся через mixer. */
/* Дальше идут хаки, вызванные тем, что не все правки успел пропустить через релизный цикл mixer */

/* .grid нужен просто для увеличения веса правила. */

.grid.dirtyStyleHack
    .combo
    [class*="banner-choice_BannerChoice__item__"]
    [class*="banner-choice_BannerChoice__imgContainer__"]
    [class*="banner-choice_BannerChoice__icon__"] {
    width: 24px;
    height: 24px;
}

.grid.dirtyStyleHack
    .combo
    [class*="banner-choice_BannerChoice__item__"]
    [class*="banner-choice_BannerChoice__text__"] {
    color: #18181b;
}

/* Конец блока хаков. */
