.container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px 12px;
    border-radius: 24px;
    background-color: #18181b80;
}

[class*="red-snippet_RedSnippet__container"] .container {
    border-radius: 6px;
}

.hidingCompleteOverlay {
    pointer-events: none;
}
.hidingCompleteOverlay [class*="red-snippet_RedSnippet__contentWithAside"],
.hidingCompleteOverlay [class*="red-snippet_RedSnippet__promoTags"],
.hidingCompleteOverlay [class*="product-snippet_ProductSnippet__rightTags"] {
    opacity: 0.6;
}

.text {
    color: #fff;
}

.button {
    position: absolute;
    bottom: 8px;
    right: 10px;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    padding: 0;
    border-radius: 6px;
    outline: none;
    user-select: none;
    border: none;
    cursor: pointer;
    pointer-events: auto;
    color: #e6eaf0;
    background-color: #22222233;
}

.button svg {
    width: 17px;
    height: 16px;
}
